import React from 'react';
import _ from 'lodash';
import { graphql } from 'gatsby';

import {Layout} from '../components/index';
import SubscribeBlock from '../components/SubscribeBlock'
import {htmlToReact, Link} from '../utils';
import Img from 'gatsby-image';
import styled from'styled-components';

import SocialIcons from '../components/SocialIcons';

const SplitContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  background-color: ${props => props.bg || 'white'};

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Trio = styled(SplitContainer)`
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const BioLink = styled(Link)`
  align-self: center;
  justify-self: center;
  width: 100%;
  position: relative;
`;

const BioText = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;

  h2, p {
    margin-bottom: 0;
    color: white;
  }
`;

const BioArticle = styled.article`
  color: white;

  h1 {
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 5px;
    font-size: 14px;
  }

  .post-content {
    margin-top: 90px;
  }
`;

export default class Bio extends React.Component {
  render() {
    const data = this.props.data;
    const images = data.images.edges;
    const posts = data.posts.edges;

    return (
      <Layout {...this.props}>
        <SplitContainer className="outer" bg="#FF6367">
          {images.map(image => {
            let imageName = image.node.fixed.originalName.replace('.jpg', '');
            if (imageName.slice(0,3) === _.get(this.props, 'pageContext.frontmatter.title').slice(0,3).toLowerCase()) {
              return(
                <Img key={image.node.fixed.originalName} fixed={image.node.fixed} style={{height: "60vh", width: "100%"}}/>
              )
            }
            return null
          })}
          <BioArticle>
            <h1>{_.get(this.props, 'pageContext.frontmatter.title')}</h1>
            <p>{_.get(this.props, 'pageContext.frontmatter.job')}</p>
            <div style={{display: 'flex'}}>
              <SocialIcons 
                twitter={_.get(this.props, 'pageContext.frontmatter.twitter')}
                linkedin={_.get(this.props, 'pageContext.frontmatter.linkedin')}
                instagram={_.get(this.props, 'pageContext.frontmatter.instagram')}
              />
            </div>
            <div className="post-content">
              {htmlToReact(_.get(this.props, 'pageContext.html'))}
            </div>
          </BioArticle>
        </SplitContainer>
        <Trio className="outer" bg="#FF6367">
          {posts.map( post => {
            let firstn = post.node.fields.name;
            let url = post.node.fields.url;
            let fulln = post.node.frontmatter.title;
            let job = post.node.frontmatter.job;
            let photo;

            images.map(image => {
              let imageName = image.node.fixed.originalName.replace('.jpg', '');
              if (imageName === firstn) {
                photo = image.node.fixed
              }
              return null
            })

            if (fulln !== _.get(this.props, 'pageContext.frontmatter.title')) {

              return(
                <BioLink to={url} key={firstn}>
                  <Img fixed={photo} style={{height: "60vh", width: "100%"}}/>
                  <BioText>
                    <h2>{fulln}</h2>
                    <p>{job}</p>
                  </BioText>
                </BioLink>
              )
            }
            return null
          })}
        </Trio>
        <SubscribeBlock />
      </Layout>
    );
  }
}

export const query = graphql`
  {
    posts: allMarkdownRemark(filter: {fields: {url: {in: ["/about/shane/""/about/jeff/""/about/david/""/about/kevin/"]}}}) {
      edges {
        node {
          fields {
            name
            url
          }
          frontmatter {
            title
            job
          }
        }
      }
    }
    images: allImageSharp(filter: {fixed: {originalName: { in: ["david.jpg""shane.jpg""jeff.jpg""kevin.jpg"]}}}) {
      edges{
        node {
          fixed {
            originalName
            src
            srcSet
            srcWebp
            srcSetWebp
          }
        }
      }
    }
  }
`
