import React from 'react'
import styled from 'styled-components';
import {Link} from '../utils';

import twitter from '../images/icons/twitter.svg';
import linkedin from '../images/icons/linkedin.svg';
import instagram from '../images/icons/ig.svg';



export default function SocialIcons(props) {
  let bg_styles

  if (props.bg) bg_styles = `
    a {
      display: flex;
      align-self: center;
      justify-self: center;
      background-color: #2456EA;
      padding: 0.2rem;
      border-radius: 3px;
    }
  `

  const SocialContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;

    ${ bg_styles }
  `;

  return (
    <SocialContainer className={props.className}>
      {props.twitter ? <Link to={props.twitter}><img src={twitter} alt="" /></Link> : ''}
      {props.instagram ? <Link to={props.instagram}><img src={instagram} alt="" /></Link> : ''}
      {props.linkedin ? <Link to={props.linkedin}><img src={linkedin} alt="" /></Link> : ''}
    </SocialContainer>
  )
}
